<template>
  <div class="container" id="container">
    <div class="header">
      <a href="javascript:;" class="back" @click="back">返回</a>
      <h1>推荐线路</h1>
    </div>
    <div class="list">
      <a href="javascript:;" @click="detail(item.groupId)" v-for="(item, index) in allLine" :key="index">
        <div class="info">
          <h1>{{item.title}}</h1>
          <li>{{item.areaNames}} | {{item.playPlacesNumber}}个观光点</li>
          <span>开始阅读</span>
        </div>
        <div class="img">
          <img :src="item.cover" :alt="item.title" />
        </div>
      </a>
    </div>
    <div class="loading" v-show="loading"> <img src="../../hotel/img/loading.png" alt="正在加载" />正在加载 </div>
    <div class="nomore" v-show="nomore">没有更多了~</div>
    <div class="nodata" v-show="noData">没有相关推荐</div>
  </div>
</template>
<script>
  export default {
    name: "index",
    data() {
      return {
        api: {
          type: "/group/queryGroupType", //路线类型
          line: "/group/queryGroupByRecommend" //路线列表
        },
        groupTypeId: null, // 类型id
        allLine: [], // 全部线路
        pageIndex: 1, // 当前页码
        pageSize: 10, // 每页列表数量
        totalPage: 0, // 总页数
        collectable: true, // 服务器数据采集，第一页时，为true
        playPeopleNumber: 1, // 游玩人数
        playDaysTypeId: null, // 游玩天数枚举id
        includeOldMan: false, // 有老人
        includeChildren: false, // 有小孩
        attractionsIds: [], // 景点id集合
        loading: true, // 正在加载
        nomore: false, // 没有更多了
        noData: false // 暂无数据
      }
    },
    created() {
      this.getParams()
      this.getType() // 获取类型
    },
    mounted() {
      //监听scroll事件
      window.addEventListener("scroll", this.handleScroll, true);
    },
    destroyed() {
      //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      // 获取筛选条件
      getParams() {
        let paramsData = JSON.parse(this.$route.query.params), attractionsIds = []
        for(let i = 0; i < paramsData.regions.length; i++){
          attractionsIds.push(paramsData.regions[i].id)
        }
        this.playPeopleNumber = paramsData.persons
        this.playDaysTypeId = paramsData.days.value
        this.includeOldMan = paramsData.hasAged
        this.includeChildren = paramsData.hasChild
        this.attractionsIds = attractionsIds
      },
      // 获取类型
      getType() {
        let _this = this;
        this.$get({
          url: _this.api.type,
          params: {}
        }).then((rsp) => {
          _this.groupTypeId = rsp[0].groupTypeId
          this.getAllLine() // 获取推荐线路
        })
      },
      // 获取推荐线路
      getAllLine() {
        let _this = this;
        this.loading = true
        this.$post({
          url: _this.api.line,
          params: {
            groupTypeId: _this.groupTypeId,
            pageIndex: _this.pageIndex,
            pageSize: _this.pageSize,
            collectable: _this.collectable,
            playPeopleNumber: _this.playPeopleNumber,
            playDaysTypeId: _this.playDaysTypeId,
            includeOldMan: _this.includeOldMan,
            includeChildren: _this.includeChildren,
            attractionsIds: _this.attractionsIds
          }
        }).then((rsp) => {
          this.loading = false
          _this.collectable = false
          _this.totalPage = Math.ceil(rsp.data.count / _this.pageSize) //总页数
          _this.allLine = _this.allLine.concat(rsp.data.data)
          if(_this.pageIndex == _this.totalPage){
            _this.nomore = true
          }
          if(rsp.data.count == 0){
            _this.noData = true
          }
        })
      },
      // 监听滚动条
      handleScroll() {
        let scrollTop = document.getElementById("app").scrollTop;
        let windowHeight = document.getElementById("app").clientHeight;
        let bodyHeight = document.getElementById("container") ? document.getElementById("container").clientHeight - 10 : 0;
        if (scrollTop + windowHeight + 30 >= bodyHeight && !this.loading && this.pageIndex < this.totalPage) {
          this.pageIndex = this.pageIndex + 1
          this.getAllLine()
        }
      },
      // 查看详情
      detail(v) {
        this.$router.push(`/lineTwoDetails/?from=list&groupId=${v}`)
        sessionStorage.setItem('key',1)
      },
      // 后退
      back() {
        window.history.back()
      }
    }
  }
</script>
<style scoped>
  .container{
    background: #f0f0f0;
    padding: 50px 15px 15px 15px;
    font-size: 0;
    color: #fff;
    text-align: center;
    min-height: calc(100vh - 65px);
  }
  .container em, .container li, .container h1{
    font-style: normal;
  }
  .container li{
    list-style-type: none;
  }
  .header{
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: #fff;
    z-index: 10;
    box-shadow: 0 0 5px #ccc;
  }
  .header a{
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    background: url(../../hotel/img/black_back.png) no-repeat center center/auto 18px;
    font-size: 0;
  }
  .header h1{
    color: #333;
    font-size: 16px;
    line-height: 50px;
  }
  .list a{
    display: block;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    margin-top: .26rem;
  }
  .list a .info{
    width: 60%;
    height: 95px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
  }
  .list a .info h1{
    height: 40px;
    line-height: 20px;
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .list a .info li{
    height: 20px;
    line-height: 20px;
    font-size: 13px;
    color: #888;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 5px 0;
  }
  .list a .info span{
    height: 25px;
    line-height: 22px;
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid #1fdf50;
    border-radius: 3px;
    color: #1fdf50;
    font-size: 14px;
    padding: 0 10px;
    font-weight: bold;
  }
  .list a .img{
    width: 40%;
    height: 95px;
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
  }
  .list a .img img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  .loading {
    font-size: 0.4rem;
    padding: 0.2rem;
    color: #ccc;
    text-align: center;
  }
  .loading img {
    height: 0.4rem;
    vertical-align: middle;
    margin: -0.1rem 0.1rem 0 0;
    animation: loading 0.5s linear infinite;
  }
  /*数据加载动画*/
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(180deg);
    }
  }
  .nomore{
    text-align: center;
    font-size: 12px;
    line-height: 40px;
    color: #888;
  }
  .nodata{
    text-align: center;
    font-size: 16px;
    line-height: 60px;
    color: #888;
  }
</style>
